import requset from "utils/request";
import baseURL from "./env";
export function payMoney(params) {
  return requset({
    url: `${baseURL.apiURL}/consume/core/pay/order/wx`,
    method: "POST",
    params
  });
}
export function paypalorder(params) {
  return requset({
    url: `${baseURL.apiURL}/consume/core/pay/order/paypal`,
    method: "POST",
    params
  });
}
export function zfMoney(params) {
  return requset({
    url: `${baseURL.apiURL}/consume/core/pay/order/ali`,
    method: "POST",
    params
  });
}

export function payMoneyList(params) {
  return requset({
    url: `${baseURL.apiURL}/consume/core/pay/cdn/grade`,
    method: "GET",
    params
  });
}
export function paySuccess(params) {
  return requset({
    url: `${baseURL.apiURL}/consume/core/pay/query/order`,
    method: "GET",
    params
  });
}
export function payOrder(params) {
  return requset({
    url: `${baseURL.apiURL}/consume/core/pay/list/order`,
    method: "GET",
    params
  });
}
/* 找回订单 */

export function payisSuccess(params) {
  return requset({
    url: `${baseURL.apiURL}/consume/core/pay/order/retrieve`,
    method: "POST",
    params
  });
}
/* ===消费记录 */
export function groupPay(params) {
  return requset({
    url: `${baseURL.apiURL}/consume/record/list/group/type`,
    method: "GET",
    params
  });
}
// 二级
export function groupBook(params) {
  return requset({
    url: `${baseURL.apiURL}/consume/record/list/group/book`,
    method: "GET",
    params
  });
}
// 三级
export function bookRecord(params) {
  return requset({
    url: `${baseURL.apiURL}/consume/record/list/book`,
    method: "GET",
    params
  });
}
