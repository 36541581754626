<template>
  <div class="bgColor">
    <topNav title="充值記錄" :border="true"></topNav>
    <div class="userinfo">
      <div class="con clearfix">
        <div class="img fl">
          <img
            class="fl"
            v-if="info.iconUrlSmall"
            :src="info.iconUrlSmall"
            alt=""
          />
          <img
            v-if="!info.iconUrlSmall"
            src="~img/default_head@2x.png"
            alt=""
          />
        </div>
        <div class="info fl">
          <p class="name">{{ info.name }}</p>
          <p class="id">ID：{{ info.id }}</p>
          <p style="position:relative">
            餘額：{{ userInfo.yuanBi }}<span>書幣</span> | 0<span>贈幣</span>
            <button
              class="userId"
              @click="copyId"
              :data-clipboard-text="info.id"
            >
              複製ID
            </button>
          </p>
        </div>
      </div>
    </div>
    <ul
      class="list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="10"
    >
      <li v-if="rechangeList && rechangeList.length > 0" v-for="item in rechangeList" class="clearfix" :key="item.id">
        <div class="left fl">
          <p>單號：{{ item.id }}</p>
          <p>充值方式：{{ item.channelName }}</p>
          <p>充值狀態：{{ item.status }}</p>
          <p>時間：{{ item.createTime }}</p>
        </div>
        <div class="right fr">{{ item.money }}元</div>
      </li>
    </ul>
    <div v-if="showMore&&rechangeList && rechangeList.length > 0" class="nonehint">
      <img src="~img/account/dingdan.png" alt="" />
    </div>
    <nodata class="posCen" v-if="rechangeList && rechangeList.length == 0">
      <div slot="datatitle" class="nodata-tip">暫無充值記錄！</div>
    </nodata>
  </div>
</template>

<script>
import { getuserInfo } from "apiurl/user";
import { mapState } from "vuex";
import { payOrder } from "apiurl/pay";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      userInfo: {},
      rechangeList: null,
      pageNum: 0,
      loading: false,
      hasNextPage: true,
      showMore: false
    };
  },
  created() {
    this.$nextTick(() => {
      // 獲取用戶信息
      this.getUserInfo();
    });
  },
  computed: mapState({
    info: state => state.user.img
  }),
  methods: {
    seeBack() {
      this.$router.go(-1);
    },
    async getUserInfo() {
      let res = await getuserInfo();
      if (res.success) {
        this.userInfo = res.data;
      }
    },
    gotoup() {
      this.$router.push("/toup");
    },
    // 複製Id
    copyId() {
      let clipboard = new Clipboard(".userId");
      clipboard.on("success", () => {
        this.Toast({
          message: "複製成功",
          duration: 2000,
          position: "top"
        });
        // 釋放內存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.Toast({
          message: "該瀏覽器不支持自動複製",
          duration: 2000,
          position: "top"
        });
        // 釋放內存
        clipboard.destroy();
      });
    },
    async loadMore() {
      if (this.hasNextPage) {
        this.loading = false;
        this.pageNum++;
        let res = await payOrder({ pageNo: this.pageNum, pageSize: 10 });
        if (res.success) {
          this.hasNextPage = res.data.hasNextPage;
          this.rechangeList = this.rechangeList?[...this.rechangeList, ...res.data.items]:res.data.items;
          if (this.rechangeList.length < 10) {
            this.showMore = true;
          }
        }
      } else {
        this.loading = true;
        this.showMore = true;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.bgColor
    width 100vw
    min-height: 100vh
    background:rgba(249,249,249,1);
.userinfo
    padding 133px 55px 36px
    color #999
    font-size 26px
    position relative
    .info
        padding-top 4px
    .img
        width 129px
        height 129px
        border-radius 50%
        margin-right 22px
        img
            width 100%
            height 100%
            border-radius 50%
    .name
        font-size 32px
    .id
        font-size 24px
        line-height 33px
        margin-bottom 10px
    span
        font-size 22px
    button
        width:130px;
        height:45px;
        background:linear-gradient(135deg,rgba(122,209,255,1) 0%,rgba(29,133,241,1) 100%);
        box-shadow:0px 2px 10px 0px rgba(118,206,254,1);
        border-radius:23px;
        position absolute
        left 359px
        bottom 0px
        color #fff
.list
    li
        padding 23px 29px 27px 33px
        margin 0 21px 24px 28px
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 12px 0px rgba(0,0,0,0.07);
        border-radius:24px;
        color #555555
        font-size 26px
        line-height 40px
        .right
            font-size:36px;
            height 160px
            line-height 160px
            font-weight:500;
            color #999
.nonehint
    margin 65px auto 0
    padding-bottom 30px
    width 209px
    img
        width 209px
</style>
